import { Component, OnInit, Input, ViewChild, Renderer2, HostListener } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/shared/models/user.model';
import { TokenService } from 'src/app/shared/services/token.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it'
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
registerLocaleData(localeIt, 'it');
declare var $: any;

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  user: UserModel;
  @Input() product: Product;
  @Input() gridFlex: boolean = true; // Default False 
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() collection: boolean = false;
  public selectedSize: any;
  isMobile: boolean = false;
  Variants: any = [];
  VariantCode;
  ProductVariantCode: any = {};
  Balance;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string
  public ImageSrc2: string
  products: any = [];
  routerurl;

  constructor(private productService: ProductService, private toastrService: ToastrService, private toastr: ToastrService, public tokenService: TokenService, private render: Renderer2,private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,) {
      this.breakpointObserver.observe([
        "(max-width: 768px)"
      ]).subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }
 
  public ngOnInit(): void {
    this.VariantCode = this.product.StockVariants.length ? this.product.StockVariants[0].VariantCode : "";
    this.ProductVariantCode = this.product.StockVariants.length ? this.product.StockVariants[0] : "";
    this.product.ProductVariant = this.ProductVariantCode;
    if (this.collection) {
      this.routerurl = "..";
    }
    else {
      this.routerurl = "";
    }
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 50); // Skeleton Loader
    }
  }
  
  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if($('#cart-info').width() >= 200) this.gridFlex = true;
    else this.gridFlex = false;

  }

  Wishlist() {
    if (this.tokenService.getUser()) {
      if (this.products.length <= 0) {
        this.products = this.productService.getwishlistItems;
        if (this.products.find(x => x.ProductVariant.Barcode === this.ProductVariantCode.Barcode)) {
          return true
        }
        else {
          return false
        }
      }
      else {
        if (this.products.find(x => x.ProductVariant.Barcode === this.ProductVariantCode.Barcode)) {
          return true
        }
        else {
          return false
        }
      }
    }
    else {
      return false
    }

  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].VariantCode) === -1 && variants[i].VariantCode) {
        uniqColor.push(variants[i])
      }
    }
    return uniqColor
  }
  // Change Variants
  ChangeVariants(color, product, event, id) {

    product.StockVariants.find(item => {
      if (item.ColorName === color) {
        this.ImageSrc = item.Images[0].ImageLocation;
        this.ImageSrc2 = item.Images[1].ImageLocation;
        this.ProductVariantCode = item;
        this.product.ProductVariant = item;
        this.VariantCode = item.VariantCode;
      }
    });
    $('#color-variant' + id + ' li').removeClass("color-variant-active");
    this.render.addClass(event.target.parentElement, "color-variant-active");
  }
  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }
  async addToCart(product: any) {
    product.ProductVariant = this.ProductVariantCode;
    product.CartAmount = this.product.SetCount;
    product.IsSoldOutPage = true;
    const status = await this.productService.addToCart(product);
    if (status) {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.success(translate['notification:product-added-cart']);

    }
  }
  Size(ItemSizes) {
    const uniqSize = [];
    for (let i = 0; i < Object.keys(ItemSizes).length; i++) {
      if (uniqSize.indexOf(ItemSizes[i]) === -1 && ItemSizes[i]) {
        uniqSize.push(ItemSizes[i])
      }
    }
    return uniqSize
  }
  addToWishlist(product: any) {
    product.ProductVariant = this.ProductVariantCode;
    product.CartAmount = this.product.SetCount;
    this.productService.addToWishlist(product);
    this.products = this.productService.getwishlistItems;
  }
  removeToWishlist(product: any) {
    product.ProductVariant = this.ProductVariantCode;
    this.productService.removeWishlistItem(product);
    this.products = this.productService.getwishlistItems;
  }
  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}
