import { Component, OnInit, Injectable, PLATFORM_ID, Inject, Input, ChangeDetectorRef, EventEmitter, Output, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../services/token.service';
import { UserModel } from '../../models/user.model';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router, Event } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { map } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-settings',
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(300, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(300, style({ height: '*' }))
      ])
    ]),
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  user: UserModel;
  LoginModel: any = {};
  public products: Product[] = [];
  public wishlist: Product[] = [];
  currentLanguage: any = {};
  currentLangLink;
  routerurl;
  Wish;
  Cart;
  IsSearching;
  @Output() myevent: EventEmitter<any> = new EventEmitter();


  SearchModel: any = {};
  Searching;
  IsLoadingSearch;
  SearchProducts: any = [];
  First;
  timer;
  time = 500;


  isLoginLoading = false;
  isSearchPage = false;
  @Input() sticky: boolean;
  @Input() home: boolean;

  public languages = [{
    name: 'English',
    code: 'en',
    flag: 'gb'
  }, {
    name: 'Türkçe',
    code: 'tr',
    flag: 'tr'
  }, {
    name: 'Russian',
    code: 'ru',
    flag: 'ru'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.IsSearching = false;
    this.SearchModel.SearchText = null;
    this.SearchProducts = [];
    this.myevent.emit({ IsSearching: false });
    this.Searching = false;
  }
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private tokenService: TokenService, private activeRoute: ActivatedRoute,
    private translate: TranslateService, private router: Router, private http: HttpClient, private toastr: ToastrService, private cdref: ChangeDetectorRef,
    public productService: ProductService, private localize: LocalizeRouterService) {

    let translatedPath: any = this.localize.translateRoute('/search');
    if (this.router.url.split('?')[0] == translatedPath) {
      this.isSearchPage = true;
    }
    else {
      this.isSearchPage = false;

    }

    if (this.tokenService.getUser()) {
      this.productService.cartItems.subscribe(response => this.products = response);
      this.productService.wishlistItems.subscribe(response => this.wishlist = response);
    }

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.SearchProducts = [];
        this.IsSearching = false;
        this.SearchModel.SearchText = null;
        this.Searching = false;
        this.myevent.emit({ IsSearching: false });
        let translatedPath: any = this.localize.translateRoute('/search');
        if (this.router.url.split('?')[0] == translatedPath) {
          this.isSearchPage = true;
        }
        else {
          this.isSearchPage = false;
        }

        this.user = this.tokenService.getUser();
        if (this.tokenService.getUser()) {
          this.productService.cartItems.subscribe(response => this.products = response);
          this.productService.wishlistItems.subscribe(response => this.wishlist = response);
        }
        if (this.localize.parser.currentLang) {
          this.currentLanguage = this.languages.find(x => x.code === this.localize.parser.currentLang);
        }
        else {
          this.currentLanguage = this.languages.find(x => x.code === 'en');
        }
      }
    });
  }

  ngOnInit(): void {
    this.routerurl = "/";
    this.user = this.tokenService.getUser();
    if (this.localize.parser.currentLang) {
      this.currentLanguage = this.languages.find(x => x.code === this.localize.parser.currentLang);
    }
    else {
      this.currentLanguage = this.languages.find(x => x.code === 'en');
    }

  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public switchLang(code) {
    this.localize.changeLanguage(code);
    this.currentLanguage = this.languages.find(x => x.code === code);
  }
  public get getAmount(): Observable<number> {
    return this.productService.cartItemsAmount();
  }
  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  Login() {
    this.isLoginLoading = true;
    const body = { Email: this.LoginModel.mail, Password: this.LoginModel.password };
    this.http.post<any>(environment.url + 'Login/Authenticate', body).subscribe(data => {
      if (data) {
        this.isLoginLoading = false;
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("tokenVIP", data);
        }
        this.user = this.tokenService.getUser();
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem("wishlistItems");
        }

        this.wishlist = [];
        this.Wish = this.http.get<Product[]>(environment.url + 'Wish/GetWishList?LangCode=' + this.translate.currentLang).pipe(map(data => data));
        this.Wish.subscribe(next => {
          if (isPlatformBrowser(this.platformId)) {
            localStorage['wishlistItems'] = JSON.stringify(next);
          }
          this.productService.wishlistItems.subscribe(response => this.wishlist = response);
        });

        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem("cartItems");
        }
        this.products = [];
        this.Cart = this.http.get<Product[]>(environment.url + 'Cart/GetCartList?LangCode=' + this.translate.currentLang).pipe(map(data => data));
        this.Cart.subscribe(next => {
          if (isPlatformBrowser(this.platformId)) {
            localStorage['cartItems'] = JSON.stringify(next);
          }
          this.productService.cartItems.subscribe(response => this.products = response);
        });
        window.location.reload();

        let translate = this.translate.translations[this.translate.currentLang];
        this.toastr.success(translate['notification:login']);
        let translatedPath: any = this.localize.translateRoute('/');
        this.router.navigate([translatedPath]);
      }
    },
      error => {
        this.isLoginLoading = false;
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastr.error(translate['notification-error:username-password-incorrect']);
      });
  }
  LogOut() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("tokenVIP");
      localStorage.removeItem("tokenVIP");
      localStorage.removeItem("wishlistItems");
      localStorage.removeItem("cartItems");
    }
    this.wishlist = [];
    this.products = [];
    let translate = this.translate.translations[this.translate.currentLang];
    this.toastr.success(translate['notification:exit']);
    let translatedPath: any = this.localize.translateRoute('/');
    this.router.navigate([translatedPath]);
    this.user = this.tokenService.getUser();
  }

  gotoSearch() {
    const navigatioExtra: NavigationExtras = { queryParams: { q: this.SearchModel.SearchText } }
    let translatedPath: any = this.localize.translateRoute('/search');
    this.router.navigate([translatedPath], navigatioExtra);
  }
  ClearSearch() {
    this.SearchProducts = [];
    this.SearchModel.SearchText = null;
    this.Searching = false;
  }
  searchBar() {
    if (this.SearchModel.SearchText.length >= 3) {

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.Searching = true;
        this.IsLoadingSearch = true;
        this.http.get<any>(environment.url + 'Products/Search?searchKeys=' + this.SearchModel.SearchText + '&langCode=' + this.translate.currentLang).subscribe(data => {
          this.SearchProducts = data;
          this.IsLoadingSearch = false;
        });
      }, this.time)
    }
    else {
      this.Searching = false;
    }


  }
  CloseTheSearch() {
    this.IsSearching = false;
    this.myevent.emit({ IsSearching: false });
  }
  OpenSearchModal() {
    if (!this.isSearchPage) {
      if (this.IsSearching) {
        this.IsSearching = false;
        this.myevent.emit({ IsSearching: false });
      }
      else {
        this.IsSearching = true;
        this.myevent.emit({ IsSearching: true });
      }
    }
    else {
      this.IsSearching = false;
    }


  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }
  removeWishlistItem(product: any) {
    this.productService.removeWishlistItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
