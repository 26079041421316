import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-newsletter',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, OnDestroy {

  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  language;
  PopUpModel: any = {};
  isLoadingPopup;
  isVideo;

  @Input() responsivethemeLogodark: string = 'assets/images/icon/responsive-logo-dark.png'; // Default Logo


  constructor(@Inject(PLATFORM_ID) private platformId: Object, private localize: LocalizeRouterService, private datePipe: DatePipe, private translate: TranslateService, private router: Router, private http: HttpClient,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.isLoadingPopup = false;
    this.http.get<any>(environment.url + 'PopUp/GetPopups').subscribe(data => {
      if (data.Data.length) {
        this.PopUpModel = data.Data.find(x => x.LangCode === this.translate.currentLang.toUpperCase());
        if(this.PopUpModel.PopupImage.substring(this.PopUpModel.PopupImage.length - 3) == 'mp4') this.isVideo = true;
        if(localStorage.getItem("newsletter-counter") != this.PopUpModel.PopupCounter){
          var now = new Date();
          localStorage.setItem("old-newsletter-date", this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
          localStorage.setItem("newsletter", this.PopUpModel.PopupRefresh);
          localStorage.setItem("newsletter-counter", this.PopUpModel.PopupCounter);
          this.isLoadingPopup = true;
          this.openModal();
        }
        else{
          if (this.PopUpModel && localStorage.getItem("old-newsletter-date") && localStorage.getItem("newsletter-counter")) {
            const olddate = new Date(localStorage.getItem("old-newsletter-date"));
            var newdate = new Date(localStorage.getItem("old-newsletter-date"));
            newdate.setHours(olddate.getHours() + Number(localStorage.getItem("newsletter")));
  
            if (newdate < new Date()) {
              var now = new Date();
              localStorage.setItem("old-newsletter-date", this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
              localStorage.setItem("newsletter", this.PopUpModel.PopupRefresh);
              localStorage.setItem("newsletter-counter", this.PopUpModel.PopupCounter);
              this.isLoadingPopup = true;
              this.openModal();
            }
          }
          else {
            var now = new Date();
            localStorage.setItem("old-newsletter-date", this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
            localStorage.setItem("newsletter", this.PopUpModel.PopupRefresh);
            localStorage.setItem("newsletter-counter", this.PopUpModel.PopupCounter);
            this.isLoadingPopup = true;
            this.openModal();
          }
        }
     

      }



    });
    this.localize.routerEvents.subscribe((language: string) => {
      this.language = language;
    });
  }

  // ngAfterViewInit(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (localStorage.getItem("newsletter") != 'true') {
  //       this.openModal();
  //       localStorage.setItem("newsletter", 'true');
  //     }

  //   }
  // }


  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, {
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal myCustomModalClass'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  gotoRegisterPage() {
    let translatedPath: any = this.localize.translateRoute('/register');
    this.router.navigate([translatedPath]);
    this.modalService.dismissAll();
  }

}
