import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {
  public products: Product[] = [];
  isMobile: boolean = false;

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]


  IsSearching;
  @Input() home: boolean = false;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() responsivethemeLogo: string = 'assets/images/icon/responsive-logo.png'; // Default Logo
  @Input() responsivethemeLogodark: string = 'assets/images/icon/responsive-logo-dark.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() stick: boolean = false; // Default false
  alerts = true;
  backgroundwhite: boolean = false;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    public productService: ProductService) {
    this.breakpointObserver.observe([
      "(max-width: 1200px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  ngOnInit(): void {
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
  click(data) {
    if (data.IsSearching) this.IsSearching = true;
    else this.IsSearching = false;
  }
  CloseAlert() {
    this.alerts = false;
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 10 && window.innerWidth > 200) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
