<!--modal popup start-->
<ng-template class="theme-modal" style="max-width: 750px!important;" #newsletter let-modal>
    <div class="modal-body modal7" style="padding: 0;">
        <div class="container-fluid" style="padding: 0;" *ngIf="isLoadingPopup" [@fadeInOut]>
            <button style="position: absolute;right: 20px;top: 10px;z-index: 69;border: 1px solid;padding: 5px;"
                type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true" style="opacity: 1;">&times;</span>
            </button>
            <div class="row" style="margin: 0;">
                <div class="col-lg-6 responsive-image" style="padding: 0;">
                    <img *ngIf="!isVideo" src="https://cdn.vipstendo.com/images/{{PopUpModel.PopupImage}}"
                        style="border-top-left-radius: 3px;border-bottom-left-radius: 3px;" class="img-fluid" alt="">

                    <video *ngIf="isVideo"
                        style="-o-object-position: center;object-position: center;-o-object-fit: cover;object-fit: cover;height: 100%;width: 100%;"
                        playsinline loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                        <source src="https://cdn.vipstendo.com/images/{{PopUpModel.PopupImage}}"
                            type="video/mp4" />
                    </video>
                </div>
                <div class="col-lg-6 responsive-text-background responsive-video-newsteller" *ngIf="!isVideo"
                    [style.background-image]="'url(https://cdn.vipstendo.com/images/' + PopUpModel.PopupImage + ')'"
                    style="text-align: center;padding: 50px 15px;display: flex;">
                    <div class="row">
                        <div class="col-lg-12 mt-5">
                            <h3 style="color: black;">{{'popup:popup-welcome' | translate}}</h3>
                            <img src="assets/images/icon/logo.png" style="width: 300px;" class="img-fluid" alt="">
                        </div>
                        <div class="col-lg-12 mt-5" style="text-align: left;">
                            <span style="font-size: 18px;" [innerHTML]="PopUpModel.InnerText | sanitizeHtml">

                            </span>
                        </div>
                        <div class="col-lg-12 mt-5" style="display: flex;align-items: center;justify-content: center;">
                            <a class="btn-solid" [href]="PopUpModel.URL">
                                {{'popup:popup-button' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 responsive-text-background responsive-image" *ngIf="!isVideo"
                    style="text-align: center;padding: 50px 15px;display: flex;">
                    <div class="row">
                        <div class="col-lg-12 mt-5">
                            <h3 style="color: black;">{{'popup:popup-welcome' | translate}}</h3>
                            <img src="assets/images/icon/logo.png" style="width: 300px;" class="img-fluid" alt="">
                        </div>
                        <div class="col-lg-12 mt-5" style="text-align: left;">
                            <span style="font-size: 18px;" [innerHTML]="PopUpModel.InnerText | sanitizeHtml">

                            </span>
                        </div>
                        <div class="col-lg-12 mt-5" style="display: flex;align-items: center;justify-content: center;">
                            <a class="btn-solid" [href]="PopUpModel.URL">
                                {{'popup:popup-button' | translate}}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 responsive-video-newsteller" *ngIf="isVideo" style="padding: 0;">
                    <video *ngIf="isVideo" class="video" playsinline loop muted autoplay oncanplay="this.play()"
                        onloadedmetadata="this.muted = true">
                        <source src="https://cdn.vipstendo.com/images/{{PopUpModel.PopupImage}}"
                            type="video/mp4" />
                    </video>
                    <div class="background"></div>
                    <div class="video-div">
                        <div class="row" style="margin: 0;">
                            <div class="col-lg-12 mt-5">
                                <h3 style="color: black;">{{'popup:popup-welcome' | translate}}</h3>
                                <img src="assets/images/icon/logo.png" style="width: 300px;" class="img-fluid" alt="">
                            </div>
                            <div class="col-lg-12 mt-5" style="text-align: left;">
                                <span style="font-size: 18px;" [innerHTML]="PopUpModel.InnerText | sanitizeHtml">

                                </span>
                            </div>
                            <div class="col-lg-12 mt-5"
                                style="display: flex;align-items: center;justify-content: center;">
                                <a class="btn-solid" [href]="PopUpModel.URL">
                                    {{'popup:popup-button' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div class="col-lg-6 responsive-image" *ngIf="isVideo" style="padding: 0;">
                
                    <div class="video-div">
                        <div class="row" style="margin: 0;">
                            <div class="col-lg-12 mt-5">
                                <h3 style="color: black;">{{'popup:popup-welcome' | translate}}</h3>
                                <img src="assets/images/icon/logo.png" style="width: 300px;" class="img-fluid" alt="">
                            </div>
                            <div class="col-lg-12 mt-5" style="text-align: left;">
                                <span style="font-size: 18px;" [innerHTML]="PopUpModel.InnerText | sanitizeHtml">

                                </span>
                            </div>
                            <div class="col-lg-12 mt-5"
                                style="display: flex;align-items: center;justify-content: center;">
                                <a class="btn-solid" [href]="PopUpModel.URL">
                                    {{'popup:popup-button' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->